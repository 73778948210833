import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import HeaderDashboard from '../header/headerDashboard';
import { useLocation } from 'react-router-dom';
import { EditBedForm } from '../forms/beds_edit';

// const useStyles = createUseStyles(style);

const Bed = (props) => {
    const [bed, setBed] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/beds/${props.match.params.number}`, {
            method: 'GET',
            credential: true,
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
            .then(async (res) => {
                if (res.status === 200) return res.json();
                throw await res.json();
            })
            .then((res) => {
                setBed(res)

            }).catch((err) => {
                console.log(err)
            })
    }, []);
    return (
        <div>
            <HeaderDashboard />
            <h1>Modifier le lit numero: {bed.number}</h1>
            <EditBedForm bed={bed}/>
        </div>
    )
}

export default Bed;
