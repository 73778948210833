import React, { useEffect, useState } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import HeaderDashboard from '../header/headerDashboard';
import { deleteRequest, startRent } from '../../actions/beds';
import {DeleteFilled, CheckCircleFilled} from "@ant-design/icons";


const Waiting = (props) => {
    const [request, setRequest] = useState([]);
    const [number, setNumber] = useState();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/request/`, {
            method: 'GET',
            credential: true,
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
            .then(async (res) => {
                if (res.status === 200) return res.json();
                throw await res.json();
            })
            .then((res) => {
                setRequest(res)
            }).catch((err) => {
                console.log(err)
            })
    }, []);

    const handleChange = (event) => {
        if (event.target) {
            setNumber(event.target.value);
        }
    }

    const deleteRequestAction = (id) => {
        deleteRequest(id);
        setRequest(request.filter(element => element._id !== id));
    }

    const startRentRequestAction = (element) => {
        const eleId = element._id
        startRent(number, element);
        deleteRequest(eleId);
        setRequest(request.filter(element => element._id !== eleId));
    }

    return (
        <div>
            <HeaderDashboard />
            <h1>Demande en cours</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Pharmacie</th>
                        <th>Adresse</th>
                        <th>Date / Heure</th>
                        <th>Commentaire</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {request.map((element, i) => {
                        return (
                            <tr>
                                <td>{element.client}</td>
                                <td>{element.pharmacy}</td>
                                <td>
                                    <a href={"https://maps.google.com/?q=" + element.address} target="_blank">
                                        {element.address}
                                    </a>
                                </td>
                                <td>{new Date(element.date).toLocaleString()}</td>
                                <td>{element.comment}</td>
                                <td>
                                <input type="number" className="form-control" placeholder='Lit numéro' onChange={handleChange} />
                                <CheckCircleFilled onClick={() => startRentRequestAction(element)} />
                                <DeleteFilled onClick={() => deleteRequestAction(element._id)}/>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table >
        </div>
    )
}

export default Waiting;
