import React, { useEffect, useState } from 'react';
import HeaderDashboard from '../header/headerDashboard';
import { Table } from 'react-bootstrap';

const Histories = (props) => {

    const [histories, setHistories] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/histories/`, {
            method: 'GET',
            credential: true,
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
            .then(async (res) => {
                if (res.status === 200) return res.json();
                throw await res.json();
            })
            .then((res) => {
                setHistories(res)
            }).catch((err) => {
                console.log(err)
            })
    }, []);
    return (
        <div>
            <HeaderDashboard />
            <h1>Historique</h1>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Numéro</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Client</th>
                        <th>Pharmacie</th>
                    </tr>
                </thead>
                <tbody>
                    {histories.map((element, i) => {
                        return (
                            <tr
                                key={i}
                                className={element.status === 'Paiement' ? 'table-info' : ''}
                            >
                                <td>
                                    <span className="d-none d-md-inline">Lit numéro: </span>
                                    <span className="d-inline d-md-none">#</span>
                                    {element.number}
                                </td>
                                <td>{element.status}</td>
                                <td>{new Date(element.createdAt).toLocaleDateString()}</td>
                                <td>{element.client}</td>
                                <td>{element.pharmacy}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table >
        </div>
    )
}

export default Histories;
