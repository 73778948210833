import React from 'react';
import { createUseStyles } from "react-jss";
import style from "./dashboard_styles";
import HeaderDashboard from "../header/headerDashboard";
import BedsData from "../beds/bedsData";

const useStyles = createUseStyles(style);

const  Dashboard = ({match: {url}}) => { 
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <HeaderDashboard />
            <div className={classes.body}>
                <BedsData />
            </div>
        </div>
    );
};

export default Dashboard
