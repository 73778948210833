import React from 'react';
import Logo from "../../images/logo.png";
import '../../fonts/Audiowide-Regular.ttf';
import '../../fonts/Questrial-Regular.ttf';
import Header from "../header/header";
import Footer from "../footer/footer";
import { createUseStyles } from "react-jss";
import style from "./home_styles";
const useStyles = createUseStyles(style);

const  Home = () => {
    const classes = useStyles();
    return (
        <div>
        <Header />
            <div className="Core">
                <form>
                    <div className={classes.CompoHome}>
                        <img className={classes.img} src={Logo}/>
                        <div className={classes.CompoTitle}>
                            <span className={classes.Title}>Pharmagroup</span>
                            <span className={classes.SubTitle}>Lits médicaux</span>
                            <span className={classes.TopText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit.</span>
                            <p className={classes.Text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales dolor non enim laoreet vestibulum. Cras luctus nisi at massa.

</p>
                            <p></p>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
}

export default Home
