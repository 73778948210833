export default {
    button: {
        padding: '10px 20px',
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        backgroundColor: '#ddd'
    },
    text: {
        fontSize: 16,
        color: '#444',

    }
}
