import React, { useEffect, useState } from 'react';
import { createUseStyles } from "react-jss";
import style from "./beds_style";
import { Dropdown, Table } from 'react-bootstrap';
import Popup from '../popup/popup';
import { CreateBedForm } from '../forms/beds_create';
import { DetailBedForm } from '../forms/beds_details';
import { deleteBed, endRent } from '../../actions/beds';
import {useSelector} from "react-redux";

const useStyles = createUseStyles(style);

const BedsData = () => {
    const user = useSelector(state => state.user.user);
    const classes = useStyles();
    const [beds, setBeds] = useState([]);

    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [openDetail, setIsOpenDetail] = useState(null);

    const togglePopupCreate = () => {
        setIsOpenCreate(!isOpenCreate);
    }

    const togglePopupDetail = (bed) => {
        setIsOpenDetail(bed ? bed : null);
    }

    const deleteBedAction = (number) => {
        deleteBed(number);
        setBeds(beds.filter(element => element.number !== number));
    }

    const endRentAction = (number) => {
        endRent(number);
        setTimeout(function() {
            window.location.reload(false);
        }.bind(this), 1000)
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/beds/`, {
            method: 'GET',
            credential: 'include',
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
            .then(async (res) => {
                if (res.status === 200) return res.json();
                throw await res.json();
            })
            .then((res) => {
                setBeds(res)
            }).catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Numéro</th>
                    <th className="d-none d-md-table-cell">Status</th>
                    <th>Client</th>
                    <th>Pharmacie</th>
                    {user.role === 'admin' && (
                        <>
                            <th>
                                <button type="button" className="btn btn-dark" onClick={togglePopupCreate}>Ajouter un lit</button>
                                {isOpenCreate && (
                                    <Popup
                                        content={<CreateBedForm />}
                                        handleClose={togglePopupCreate}
                                    />
                                )}
                            </th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody>
                {beds.map((element, i) => {
                    return (
                        <tr key={element.number}>
                            <td>
                            <button className="btn btn-primary" onClick={() => togglePopupDetail(element)}>
                                <span className="d-none d-md-inline">Lit numéro: </span>
                                <span className="d-inline d-md-none">#</span>
                                {element.number}
                            </button>
                            </td>
                            <td className="d-none d-md-table-cell">{element.isRent ? <i className="icon fa fa-bed"></i> : <i className="icon fa fa-check"></i>}</td>
                            <td>{element.client}</td>
                            <td>{element.pharmacy}</td>
                            {user.role === 'admin' && (
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Paramètres
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href={"/bed/" + element.number}>Modifier</Dropdown.Item>
                                            <Dropdown.Divider />
                                            {element.isRent ? <Dropdown.Item onClick={() => {
                                                endRentAction(element.number);
                                            }}>Fin de location</Dropdown.Item> : ''}
                                            <Dropdown.Item className={classes.delete} onClick={() => {
                                                deleteBedAction(element.number);
                                            }}>Supprimer</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            )}
                        </tr>
                    );
                })}
            </tbody>
            {openDetail && <Popup
                content={<DetailBedForm bed={openDetail} />}
                handleClose={() => togglePopupDetail(null)}
            />}
        </Table >
    );
}

export default BedsData;
