import React, { useCallback } from 'react';

const DetailBedForm = (props) => {
    return (
        <div className="container mt-5">
            <div className="card shadow-sm">
                <div className="card-header bg-primary text-white">
                <h1 className="h4">Détail du lit: #{props.bed.number}</h1>
                </div>
                <div className="card-body">
                <div className="form-group mb-4">
                    <h2 className="h5">Modèle</h2>
                    <p className="text-muted">{props.bed.model}</p>
                </div>
                <div className="form-group mb-4">
                    <h2 className="h5">Nombre de moteur</h2>
                    <p className="text-muted">{props.bed.motors}</p>
                </div>
                <div className="form-group mb-4">
                    <h2 className="h5">Année</h2>
                    <p className="text-muted">{props.bed.year}</p>
                </div>
                </div>
            </div>
        </div>
    );
};

export {
    DetailBedForm
};