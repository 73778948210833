import React, { useEffect, useState } from 'react';
import HeaderDashboard from '../header/headerDashboard';
import { RequestBedForm } from '../forms/beds_request';

const Request = (props) => {
    return (
        <div>
            <HeaderDashboard />
            <h1>Nouvelle demande</h1>
            <RequestBedForm/>
        </div>
    )
}

export default Request;
