import React, {useCallback, useState} from 'react';
import { createUseStyles } from "react-jss";
import style from "./header_styles";
import {Dropdown} from "../../design_system/dropdown/dropdown";
import {Card} from "../../design_system/card/card";
import {LogoutOutlined, SettingOutlined, PlusCircleOutlined, UserOutlined, MailOutlined} from "@ant-design/icons";
import Logo from "../../images/logo.png";
import {Link} from "react-router-dom";
import {logout} from "../../actions/auth";
import {useDispatch, useSelector} from "react-redux";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink } from 'react-bootstrap';

const useStyles = createUseStyles(style);

const  HeaderDashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState();
    const user = useSelector(state => state.user.user);

    const mouseEnterHandler = useCallback(() => {
        setIsOpen(true);
    },[]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    },[]);

    const handleLogout = useCallback(()=> {
        logout()(dispatch)
    },[]);

    return (
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls='navbarScroll' data-bs-targer="#navbarScroll"/>
            <Navbar.Brand>
                Pharmagroup | Bonjour {user.name}
            </Navbar.Brand>
            <Navbar.Collapse id="navbarScoll">
                <Nav>
                    <NavLink eventKey="1" as={Link} to="/dashboard">Dashboard</NavLink>
                    <NavLink eventKey="2" as={Link} to="/new_request">Nouvelle demande</NavLink>
                    <NavLink eventKey="3" as={Link} to="/request">Demande en cours</NavLink>
                    {user.role === 'admin' && (
                        <NavLink eventKey="4" as={Link} to="/histories">Historique</NavLink>
                    )}
                    <NavLink eventKey="5" className="ml-auto" onClick={handleLogout}>Déconnexion</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default HeaderDashboard;
