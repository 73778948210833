import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import {TextField} from "../../design_system/text_field/text_field";
import Header from "../header/header";
import style from './register_styles';
import {register} from "../../actions/auth";
import {ButtonSystem} from "../../design_system/button_system/button_system";
import Loader from 'react-loader-spinner'
import Logo from "../../images/logo_vert.png";
import {Link, Redirect} from "react-router-dom";
import Footer from "../footer/footer";

const useStyles = createUseStyles(style);


const Register = () => {
    const {isAuth} = useSelector(store => store.user);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleMail = useCallback((event) => {
        const {value} = event.target;
        setEmail(value);
    }, []);

    const handlePassword = useCallback((event) => {
        const {value} = event.target;
        setPassword(value);
    }, []);

    const handleName = useCallback((event) => {
        const {value} = event.target;
        setName(value);
    }, []);

    const validate = useCallback(() => {
        setLoading(true);
        register(email, password, name)(dispatch)
            .then(()=> {
                setEmail("");
                setName("");
                setPassword("");
            })
            .catch(() => {
                setPassword("");
                setEmail("");
            })
            .then(() => {
                setLoading(false);
            });

    }, [email, password, name]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            validate();
        }
    },[validate]);

    if (isAuth) {
        return <Redirect to={'/dashboard'} />
    }

    return (
        <div>
            <Header/>
            <div className={classes.container}>
                <img className={classes.img} src={Logo}/>
                <hr className={classes.middleLine}></hr>
                <div className={classes.form}>
                    <label className={classes.inputContainer}>
                        <span className={classes.label}>Prénom</span>
                        <TextField
                            className={classes.input}
                            variant={'flat'}
                            onChange={handleName}
                            inputClassName={classes.innerInput}
                            value={name}
                        />
                    </label>
                    <label className={classes.inputContainer}>
                        <span className={classes.label}>Email</span>
                        <TextField
                            className={classes.input}
                            inputClassName={classes.innerInput}
                            variant={'flat'}
                            onChange={handleMail}
                            value={email}
                        />
                    </label>
                    <label className={classes.inputContainer}>
                        <span className={classes.label}>Mot de passe</span>
                        <TextField
                            type={"password"}
                            variant={'flat'}
                            className={classes.input}
                            inputClassName={classes.innerInput}
                            onChange={handlePassword}
                            onKeyPress={handleKeyPress}
                            value={password}
                        />
                    </label>

                    {!loading ? (
                        <ButtonSystem onClick={validate} className={classes.button}>
                            Créer un compte
                        </ButtonSystem>

                    ) : (
                        <Loader
                            className={classes.loader}
                            type="Oval"
                            color="#00BFFF"
                            height={45}
                            width={45}
                        />
                    )}
                    <div className={classes.linkContainer}>
                        <span className={classes.Text}>Vous avez déjà un compte ?</span>
                        <Link to={"/login"}>
                            <span className={classes.SubText}>Se connecter</span>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default Register;
