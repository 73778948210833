import React, {useEffect, useState} from 'react';
import './App.css';
import Login from "./components/login/login";
import {Route, Switch} from "react-router-dom";
import Home from "./components/home/home";
import Register from "./components/register/register";
import Dashboard from "./components/dashboard/dashboard";
import Request from './components/request/request';
import Waiting from './components/request/waiting';
import Histories from './components/histories/histories';
import Bed from "./components/bed/bed";
import {auth} from "./actions/auth";
import {useDispatch} from "react-redux";
import AuthWrapper from "./components/utils/auth_wrapper";
import AdminWrapper from "./components/utils/admin_wrapper";
import NotFound from "./components/404/404";
import Loader from "react-loader-spinner";
import Logo from "./images/logo.png";
import Cookies from 'universal-cookie';
import {openNotification} from "./actions/notifications";
import 'bootstrap/dist/css/bootstrap.min.css';

const cookies = new Cookies();

const App = () => {
    const [loading, setLoading] = useState(cookies.get('sess'));
    const dispatch = useDispatch();
    useEffect(() => {
        if (cookies.get('sess')) {
            auth()(dispatch)
                .then(({isAuth}) => setTimeout(() => {
                    setLoading(false);
                    if (!isAuth) {
                        openNotification({
                            type: 'error',
                            message: 'Authentication failed',
                            description: 'Can\`t reconnect to session, try to relog your account.'
                        });
                    }
                }, 1000));
        }

    }, []);

    if (loading) {
        return (
            <div style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src={Logo} style={{width: 250, paddingBottom: 50, position: 'absolute'}}/>
                <Loader
                    style={{position: 'absolute'}}
                    type="TailSpin"
                    color="#00BFFF"
                    height={300}
                    width={300}
                />
            </div>
        )
    }

    return (
        <div className="App">
            <Switch>
                <Route exact path="/" component={(props) => <Home {...props}/>}/>
                <Route exact path="/login" component={(props) => <Login {...props}/>}/>
                <Route exact path="/register" component={(props) => <Register {...props}/>}/>
                <Route path="/dashboard" component={(props) => <AuthWrapper><Dashboard {...props}/></AuthWrapper>}/>
                <Route path="/new_request" component={(props) => <AuthWrapper><Request {...props}/></AuthWrapper>}/>
                <Route path="/histories" component={(props) => <AdminWrapper><Histories {...props}/></AdminWrapper>}/>
                <Route path="/request" component={(props) => <AuthWrapper><Waiting {...props}/></AuthWrapper>}/>
                <Route path="/bed/:number" component={(props) => <AdminWrapper><Bed {...props}/></AdminWrapper>}/>
                <Route path="/404" component={(props) => <NotFound />} />
            </Switch>
        </div>
    );
};

export default App;
