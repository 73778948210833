import React from 'react';
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

const AdminWrapper = ({children}) => {
    const {user, isAuth} = useSelector(store => store.user);

    if (!isAuth) {
        return <Redirect to={'/login'} />
    }

    if (user.role !== 'admin') {
        return <Redirect to={'/dashboard'} />
    }
    return(children);
};

export default AdminWrapper;
