export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const ADD_LOCATION = "ADD_LOCATION";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_SERVICE = "UPDATE_SERVICE";

export const ADD_WEATHER = "ADD_WEATHER";

export const ADD_NEWS = "ADD_NEWS";
